import { Stack, MenuItem, Box, Paper, Typography } from "@mui/material"
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import { useContext, useEffect, useState } from "react"
import React from "react"
import { TSale } from "../../../../../core/types/Sale"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
} from "../../../../../core/types/Product"
import { CreditCard } from "../../../../../core/types/CreditCard"
import NumberFunctions from "../../../../../core/functions/NumberFunctions"
import { creditsCards } from "../../../../../core/constants"
import TextFieldRequired from "../../../../Inputs/TextFieldRequired"
import SelectRequired from "../../../../Inputs/SelectRequired"
import { CheckoutContext } from "../../../../../core/contexts/CheckoutContext"
import Convertions from "../../../../../core/functions/convertions"

export interface ICreditCardPaymentPrice4Props {
  visible: boolean
  control: Control<TSale, any>
  errors: FieldErrors<TSale>
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
  installments: IProductPricePaymentInstallment[] | undefined
  clearErrors: UseFormClearErrors<TSale>
}

const CreditCardPaymentPrice4 = (props: ICreditCardPaymentPrice4Props) => {
  const { visible, control, watch, installments, clearErrors } = props
  const { productPrice: data, installment } = useContext(CheckoutContext)

  const [creditCard, setCreditCard] = useState<CreditCard | undefined>()

  const paymentCardNumber = watch("payment.cardNumber")
  const paymentCardCVV = watch("payment.cardCCV")
  const paymentInstallment = watch("payment.installment")

  const firstPurchasePrice = data?.firstPurchasePrice ?? 0
  const isFirstPurchasePrice = firstPurchasePrice > 0
  const frequencyDescription = Convertions.productFrequencyToType(
    data?.frequency
  )
  const complement = isFirstPurchasePrice
    ? ` para o primeiro ${frequencyDescription.toLowerCase()}`
    : ""
  const installmentCurrent = installment?.original?.find(
    (inst) => inst.installmentNumber === paymentInstallment
  )

  const list = installments?.sort((a, b) =>
    a.installmentNumber > b.installmentNumber ? -1 : 1
  )
  const itensMenu =
    list?.map((x) => {
      const key = x.installmentNumber
      const value = NumberFunctions.formatMoneyDefault(x.value)
      const installmentFees = x.isInterestFree ? " (sem juros)" : ""
      const label = `${key}x de ${value}${complement}${installmentFees}`
      return {
        key,
        label,
      }
    }) || []

  useEffect(() => {
    var exists = false
    creditsCards.map((cc) => {
      if (cc.compare(paymentCardNumber)) {
        setCreditCard(cc)
        exists = true
      }
      return true
    })
    if (!exists) {
      setCreditCard(undefined)
    }
  }, [paymentCardNumber])

  useEffect(() => {
    if ((paymentCardCVV || "").length === 4) {
      // comentado pois foi solicitado a alteração para abrir automático as parcelas
      // depois foi pedido para remover, caso seja necessário voltar descomentar
      //handleOpenInstallment();
      document.getElementById("buttonCommit")?.focus()
    }
  }, [paymentCardCVV])

  return (
    <Box sx={{ display: visible ? "block" : "none" }}>
      <Paper
        elevation={0}
        sx={{ borderRadius: "8px", backgroundColor: "#F2F2FF" }}
      >
        <Stack direction="column" spacing={2} sx={{ p: "20px" }}>
          <TextFieldRequired
            control={control}
            fieldName="payment.cardName"
            label="Nome impresso no cartão"
            placeholder="Digite o nome impresso no cartão"
            clearErrors={clearErrors}
            required={visible}
            inputProps={{
              "data-cy": "cardName",
              "data-testid": "cardName",
            }}
            dataCyErrorLabel="cardNameError"
            backgroundColorTitle="#F2F2FF"
          />
          <TextFieldRequired
            control={control}
            fieldName="payment.cardNumber"
            label="Número do cartão"
            placeholder="Digite somente números"
            inputProps={{
              inputMode: "numeric",
              maxLength: 19,
              "data-cy": "cardNumber",
              "data-testid": "cardNumber",
            }}
            dataCyErrorLabel="cardNumberError"
            backgroundColorTitle="#F2F2FF"
            clearErrors={clearErrors}
            required={visible}
            endAdornment={
              creditCard && (
                <img
                  src={creditCard.getImageUrl()}
                  alt="credit card"
                  style={{ height: 30, objectFit: "contain" }}
                />
              )
            }
          />
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            sx={{ width: "100%" }}
          >
            <TextFieldRequired
              control={control}
              fieldName="payment.cardValidate"
              label="Validade do cartão (MM/AA)"
              placeholder="MM/AA"
              inputProps={{
                inputMode: "numeric",
                maxLength: 5,
                "data-cy": "cardValidate",
                "data-testid": "cardValidate",
              }}
              dataCyErrorLabel="cardValidateError"
              backgroundColorTitle="#F2F2FF"
              clearErrors={clearErrors}
              required={visible}
            />
            <TextFieldRequired
              control={control}
              fieldName="payment.cardCCV"
              label="Cód. de segurança (CVV)"
              placeholder="999"
              inputProps={{
                inputMode: "numeric",
                maxLength: 4,
                "data-cy": "cardCCV",
                "data-testid": "cardCCV",
              }}
              dataCyErrorLabel="cardCCVError"
              backgroundColorTitle="#F2F2FF"
              clearErrors={clearErrors}
              required={visible}
            />
          </Stack>
          <Box>
            <SelectRequired
              control={control}
              fieldName="payment.installment"
              label="Nº de parcelas"
              required={visible}
              backgroundColorTitle="#F2F2FF"
            >
              {itensMenu.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.label}
                </MenuItem>
              ))}
            </SelectRequired>
            {isFirstPurchasePrice && (
              <Typography fontSize="14px" lineHeight="16px" sx={{ mt: 1 }}>
                Renovação a partir da 2ª cobrança: {paymentInstallment}x de{" "}
                {NumberFunctions.formatMoneyDefault(installmentCurrent?.value)}{" "}
                {frequencyDescription.toLowerCase()}
              </Typography>
            )}
          </Box>
        </Stack>
      </Paper>
    </Box>
  )
}

export default CreditCardPaymentPrice4
